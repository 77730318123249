import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Masters


import pageAddEdit_CustomerMaster from "pages/Masters/pageAddEdit_CustomerMaster";

import pageAddEdit_CompanyMaster from "pages/Masters/pageAddEdit_CompanyMaster";

import pageList_DayWiseAtt from "pages/Masters/pageList_DayWiseAtt";
import pageAddEdit_EmployeeMaster from "pages/Masters/pageAddEdit_EmployeeMaster";
import pageList_AuditAtt from "pages/Masters/pageList_AuditAtt";
import pageAddEdit_GlobalOptions from "pages/Masters/pageAddEdit_GlobalOptions";
import pageList_Test from "pages/Masters/pageList_Test";

import pageAddEdit_ShiftMaster from "pages/Masters/pageAddEdit_ShiftMaster";
import pageAddEdit_EditShift from "pages/Masters/pageAddEdit_EditShift";
import pageList_EmployeeMaster from "pages/Masters/pageList_EmployeeMaster";
import pageAddEdit_CashLoan from "pages/Masters/pageAddEdit_CashLoan";
import pageAddEdit_MonthlyLoan from "pages/Masters/pageAddEdit_MonthlyLoan";
import pageAddEdit_Deposit from "pages/Masters/pageAddEdit_Deposit";
import pageReport_MemberStatement from "pages/Masters/pageReport_MemberStatement";
import pageAddEdit_JobApplication from "pages/Masters/pageAddEdit_JobApplication";
import pageAddEdit_TransferShares from "pages/Masters/pageAddEdit_TransferShares";
import MySynopsis from "pages/Masters/MySynopsis";
import OTP from "pages/Authentication/OTP";
import pageAddEdit_AccountToAccount from "pages/Masters/pageAddEdit_AccountToAccount";
import pageAddEdit_PersonToPerson from "pages/Masters/pageAddEdit_PersonToPerson";
import pageAddEdit_Support from "pages/Masters/pageAddEdit_Support";
import pageAdd_jobAdd from "pages/Masters/pageAdd_jobAdd";
import pageList_JobList from "pages/Masters/pageList_JobList";
import selectJob from "pages/Masters/selectJob";
import PersontoPerson from "pages/Masters/PersontoPerson";
import pageList_MailList from "pages/Masters/pageList_MailList";
import pageAddEdit_EntityMembership from "pages/Masters/pageAddEdit_EntityMembership";
import CashWithdrawal from "pages/Masters/CashWithdrawal";
import pageList_SellBusiness from "pages/Masters/pageList_SellBusiness";
import pageAddEdit_SellBusiness from "pages/Masters/pageAddEdit_SellBusiness";
import pageList_NewOpportunity from "pages/Masters/pageList_NewOpportunity";
import pageAddEdit_Membership from "pages/Masters/pageAddEdit_Membership";
import pageAddEdit_EntityMembershipUpdate from "pages/Dashboard/pageAddEdit_EntityMembershipUpdate";
import NewJobList from "pages/Masters/NewJobList";
import pagelist_TicketChat from "pages/Masters/pagelist_TicketChat";
import pageList_TicketList from "pages/Masters/pageList_TicketList";
import pageAddEdit_RaiseTicket from "pages/Masters/pageAddEdit_RaiseTicket";
import pageReport_MemberShareStatement from "pages/Masters/pageReport_MemberShareStatement";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/changepassword", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Masters
  { path: "/ticketchat/:id", component: pagelist_TicketChat },
  { path: "/tickets", component: pageList_TicketList },
  { path: "/raiseticket", component: pageAddEdit_RaiseTicket },
  
  { path: "/edit-membership/:id", component: pageAddEdit_Membership },

  { path: "/edit-membershipentity/:id", component: pageAddEdit_EntityMembershipUpdate },

  { path: "/add-shiftmaster", component: pageAddEdit_ShiftMaster },

  { path: "/newjoblist", component: NewJobList },

  { path: "/sharestatement", component: pageReport_MemberShareStatement },

  { path: "/maillist", component: pageList_MailList },

  { path: "/sellbusinesslist", component: pageList_SellBusiness },

  { path: "/newopportunity", component: pageList_NewOpportunity },

  { path: "/Add-SellBusiness", component: pageAddEdit_SellBusiness },

  
  { path: "/Edit-SellBusiness/:id", component: pageAddEdit_SellBusiness },

  { path: "/p2p", component: PersontoPerson },


  { path: "/witdrawcash", component: CashWithdrawal },



  { path: "/addjob", component: pageAdd_jobAdd },
  { path: "/addjob/:id", component: pageAdd_jobAdd },
  { path: "/pagejoblist", component: pageList_JobList },
  { path: "/selectjob", component: selectJob },
  { path: "/editemp-shiftmaster", component: pageAddEdit_EditShift },
  { path: "/edit-shiftmaster", component: pageAddEdit_ShiftMaster },
  
  { path: "/add-customermaster", component: pageAddEdit_CustomerMaster },

  { path: "/add-entity", component: pageAddEdit_EntityMembership },


  
  { path: "/audit-attendance", component: pageList_AuditAtt }
  
  ,

  { path: "/accountstatement", component: pageReport_MemberStatement },

  { path: "/support", component: pageAddEdit_Support },

  { path: "/accounttoaccount", component: pageAddEdit_AccountToAccount },

  { path: "/accounttoother", component: pageAddEdit_PersonToPerson },

  { path: "/cashloan", component: pageAddEdit_CashLoan },

  { path: "/deposit", component: pageAddEdit_Deposit },


  { path: "/monthlyloan", component: pageAddEdit_MonthlyLoan },

  
  { path: "/edit-customermaster/:id", component: pageAddEdit_CustomerMaster },

  { path: "/mysynopsis/:id", component: MySynopsis },


  { path: "/add-employeemaster", component: pageAddEdit_EmployeeMaster },

  { path: "/jobapplication", component: pageAddEdit_JobApplication },
  { path: "/edit-employeemaster/:id", component: pageAddEdit_EmployeeMaster },
  { path: "/masters-employeemaster", component: pageList_EmployeeMaster },


  //

  


  { path: "/listtest", component: pageList_Test },


  { path: "/transfershares/:id", component: pageAddEdit_TransferShares },

  { path: "/transfershares/:id", component: pageAddEdit_TransferShares },


  
  { path: "/add-companymaster", component: pageAddEdit_CompanyMaster },
  { path: "/edit-companymaster/:id", component: pageAddEdit_CompanyMaster },

  //

  


//Global Options
  { path: "/GlobalOptions", component: pageAddEdit_GlobalOptions },

  


  //Reports
  { path: "/daywiseatt", component: pageList_DayWiseAtt },


];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/otp", component: OTP },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
