import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Constants
import {API_WEB_URLS} from '../constants/constAPI';
import * as base_acTypes from './actionTypes';
/** Common Functions **/
//Change state value
export const Fn_ChangeStateValue = (obj, name, value , fun) => {
    if (fun !=null) {

        obj.setState({
            [name]:value 
        } , () => {
            fun();
        })
       
        }
    else
    obj.setState({[name]: value});

};

export const Fn_FillListData = (obj,gridName,apiURL, StudentCourseMapId) => {
    const request = {
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {

                if(gridName=="productData"){
                    obj.setState({
                        [gridName]: response.data.dataList,
                        rows : [Object.keys(response.data.dataList[0])],
                        isProgress : false
                    })
                }
                // else if(gridName=="HeaderMaster"){
                //     obj.setState({
                //         [gridName]: response.data.dataList,
                //         isProgress : false
                //     })
                // }
                else {
                obj.setState({
                    [gridName]: response.data.dataList,
                    isProgress : false
                })
            }


                if (response.data.dataList.length > 0){

                
                if (apiURL === API_WEB_URLS.STUDENTCOURSEMAPDETAILS+StudentCourseMapId){
                    const jsonStr = response.data.dataList;                   
                    const str = jsonStr[0].F_CourseMaster;
                    const arr = str.split(',').map(Number);
                    obj.setState({
                    [gridName] : arr
                    
                })
                }
            }

            if (response.data.dataList.length > 0){

                
                if (apiURL === API_WEB_URLS.FeesScheduleDetails+StudentCourseMapId){
                    const jsonStr = response.data.dataList;
                    const str = jsonStr[0].F_CourseMaster;
                    const arr = str.split(',').map(Number);
                    obj.setState({
                    [gridName] : arr
                    
                })
                }
            }
                
                

            } else {
                showToastWithCloseButton("error", "Some error occurred while displaying grid data");
            }
        },
    };
    obj.props.callFill_GridData(request)
};



export const Fn_GetReport = (obj,data,apiURL,gridName,isMultiPart=false) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {
                
                // obj.setState({
                //     [gridName]: response.data.response,
                //     isProgress : false
                // })
                    if(gridName=="productData"){
                        obj.setState({
                            [gridName]: response.data.response,
                            rows : [Object.keys(response.data.response[0])],
                            isProgress : false
                        })
                    }
                    else {
                        obj.setState({
                            [gridName]: response.data.response,
                            isProgress : false
                        })
                    }
            } 
            else {
                obj.setState({
                    [gridName]: [],
                    isProgress : false
                })
                showToastWithCloseButton("warning", "Data not found.");
            }
        },
    };
    obj.props.callAdd_Data_Multipart(request);
   
};



export const Fn_DisplayData = (obj,id,apiURL, page) => {
    const request = {
        id:id,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {   
                if (page  ==  "Group"){
                    obj.setState({
                        NoOfMembers: response.data.dataList[0].NoOfMembers,
                        NoOfEMIInMonth: response.data.dataList[0].NoOfEMIInMonth,
                        Amount: response.data.dataList[0].Amount,
                        InstallmentAmount: response.data.dataList[0].InstallmentAmount
                    })
                    if (response.data.dataList[0].Periodicity  == 1){
                        obj.setState({
                            IsMonthDis: false
                        }) 
                    }
                    else{
                        obj.setState({
                            IsMonthDis: true
                        }) 
                    }
                }    
                else if (page  ==  "FeesSchedule"){
                    obj.setState({
                        NoOfMembers: response.data.dataList[0].NoOfMembers,
                        NoOfEMIInMonth: response.data.dataList[0].NoOfEMIInMonth,
                        Amount: response.data.dataList[0].Amount,
                        InstallmentAmount: response.data.dataList[0].InstallmentAmount
                    })
                    if (response.data.dataList[0].Periodicity  == 1){
                        obj.setState({
                            IsMonthDis: false
                        }) 
                    }
                    else{
                        obj.setState({
                            IsMonthDis: true
                        }) 
                    }
                }            
                obj.setState({
                    formData: response.data.dataList[0]
                })
                // if(apiURL===API_WEB_URLS.COMPANY)
                // {
                //     //Fill city drop down
                //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
                //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
                // }
                // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
                // {
                //     //Fill city drop down
                //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
                //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
                // }
            } else {
                showToastWithCloseButton("error", "Some error occurred while displaying data");
            }
        },


        
    };
    obj.props.callGet_Data(request)
};






export const Fn_AddEditData = (obj, data,apiURL,pushFormName,isMultiPart=false , getid) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {


            if (response && response.status === 200) {
                obj.setState({
                    kit: response.data.data.id
                })

                obj.setState({
                    [getid]: response.data.data.id,
                    success_msg : true,
                    TAmount : 0,
                    HAmount : 0
                })

                if (getid == "rusudmr"){
                    Fn_GetReport(obj, { arguList: { id: 0 } }, "PaymentNotSetList/0/token", "productData", true);

                }

              //  console.log(response.data.data.id);

                if (arguList.id === 0)
                    showToastWithCloseButton("success", "Data added successfully");
                else
                    showToastWithCloseButton("success", "Data updated successfully");
                obj.props.history.push(pushFormName);
            } 
            else if (response && response.status === 208) {
                if (arguList.id === 0)
                    showToastWithCloseButton("warning", "Data already exists.");
                else
                    showToastWithCloseButton("warning", "Data already exists.");
            } 
            else if (response && response.status === 400) {
                if (arguList.id === 0)
                    showToastWithCloseButton("warning", "Required fields mandatory.");
                else
                    showToastWithCloseButton("warning", "Required fields mandatory.");
            } 
            else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "Some error occurred while updating data");
                else
                    showToastWithCloseButton("error", "Some error occurred while updating data");
            }
        },
    };
   
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};







export const Fn_ChangePassword = (obj,data,apiURL,pushFormName,isMultiPart=false) => {    
    const { arguList } = data;
     const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {

            if (response.data.response[0].Id>0) {
                obj.setState({
                    success_msg : true
                })
                if (arguList.id === 0)
                    showToastWithCloseButton("success", "Password Changed Successfully");
                else
                    showToastWithCloseButton("success", "Data updated successfully");
                obj.props.history.push(pushFormName);
            } else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "Old Password is wrong.");
                else
                    showToastWithCloseButton("error", "Some error occurred while updating data");
            }
        },
    } 
   
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};





export const Fn_TransactionWithdraw = (obj,data,apiURL,pushFormName,isMultiPart=false) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {

                if (response.data.response[0].Id == 0){
                    obj.setState({
                        unsuccess_msg : true,
                        isProgress : false
                    }) 
                }
                else {
                obj.setState({
                    success_msg: true,
                    isProgress : false,
                    fo : '',
                    formData : {
                        'AccountValue' : 0,
                        'membershipno' : '',
                        'MembershipTypeId' : 0,
                        'Amount' : '',
                        
                    }
                    
                })
              
            }
            } else {
                showToastWithCloseButton("error", "Some error occurred.");
            }
        },
    };
    obj.props.callAdd_Data_Multipart(request);
   
};





export const Fn_UserLogin = (obj,data,apiURL,pushFormName,isMultiPart=false) => {    
  
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
        
            if (response && response.status === 200) {
                if (arguList.id === 0)
                    showToastWithCloseButton("success", "Logged in successfully");
                else
                    showToastWithCloseButton("success", "Logged in successfully");
                obj.props.history.push(pushFormName);
            } else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "UserName or Password Incorrect!");
                else
                    showToastWithCloseButton("error", "UserName or Password Incorrect!");
            }
        },
    };
  
    if (arguList.id === 0)
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};

export const Fn_DeleteData = (obj,id,apiURL,apiURL_Display) => {    
    const request = {
        id:id,
        apiURL: apiURL,
        callback: (response) => {
            //console.log(response.status);
            if (response && response.status === 200) {
                obj.setState({
                    confirm_alert: false,
                    success_dlg: true,
                    dynamic_title: "Deleted",
                    dynamic_description: "Selected data has been deleted.",
                })
                showToastWithCloseButton("success", "Data deleted successfully");
                if(apiURL_Display)                
                Fn_FillListData(obj,'gridData',apiURL_Display);
            }
            else if (response && response.status === 408) {
                obj.setState({
                    confirm_alert: false,
                    dynamic_title: "Deleted",
                    dynamic_description: "Deleting not allowed!",
                })
                showToastWithCloseButton("warning", "Deleting not allowed!");
            }
            else if (response && response.status === 409) {
                obj.setState({
                    confirm_alert: false,
                    dynamic_title: "Deleted",
                    dynamic_description: "Selected data can not be deleted. Reference exists!",
                })
                showToastWithCloseButton("warning", "Selected data can not be deleted. Reference exists!");
            }
            else{
                obj.setState({
                    confirm_alert: false,
                    dynamic_title: "Deleted",
                    dynamic_description: "Selected data has been deleted.",
                })
                showToastWithCloseButton("error", "Some error occurred while deleting data");
            }
        },
    };
    obj.props.callDelete_Data(request);
};

export const togglemodal = (obj,formData) => {
    obj.setState(prevState => ({
        modal: !prevState.modal,
        selectedFormData: formData ? formData : {}
    }));
}




export const toggleDeleteConfirm = (obj,formData,value) => {
    obj.setState({confirm_alert: value,
        selectedFormData: formData ? formData : {}
    });
}

export const toggleDeleteSuccess = (obj,value) => {
    obj.setState({success_dlg: value});
}

function showToastWithCloseButton(toastType, message) {
    toastr.options = {
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        progressBar: true,
        timeOut: 2000
    }
    if (toastType == "success")
        toastr.success(message);
    else if (toastType == "warning")
        toastr.warning(message);
    else if (toastType == "error")
        toastr.error(message);
}


export const Fn_VerifyData = (obj,id,apiURL,apiURL_Display) => {    
    const request = {
        id:id,
        apiURL: apiURL,
        callback: (response) => {
            //console.log(response.status);
            if (response && response.status === 200) {
                obj.setState({
                    confirmverify_alert: false,
                    successverify_dlg: true,
                    dynamic_title: "Verify",
                    dynamic_description: "Selected data has been verified.",
                })
                showToastWithCloseButton("success", "Data verified successfully");
                if(apiURL_Display)                
                Fn_FillListData(obj,'gridData',apiURL_Display);
            }
            else if (response && response.status === 409) {
                obj.setState({
                    confirmverify_alert: false,
                    dynamic_title: "Verified",
                    dynamic_description: "Selected data can not be verified. Reference exists!",
                })
                showToastWithCloseButton("warning", "Selected data can not be deleted. Reference exists!");
            }
            else{
                obj.setState({
                    confirmverify_alert: false,
                    dynamic_title: "Verified",
                    dynamic_description: "Selected data has been verified.",
                })
                showToastWithCloseButton("error", "Some error occurred while verifying data");
            }
        },
    };
    obj.props.callVerify_Data(request);
};


export const toggleVerifyConfirm = (obj,formData,value) => {
    obj.setState({confirmverify_alert: value,
        selectedFormData: formData ? formData : {}
    });
}

export const toggleVerifySuccess = (obj,value) => {
    obj.setState({successverify_dlg: value});
}
/***/
