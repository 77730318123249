import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {

    const obj = JSON.parse(localStorage.getItem("authUser"));


    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isDashboard: !this.state.isDashboard })
                      }}
                      to="/dashboard"
                    >
                      
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isMyLoans: !this.state.isMyLoans })
                      }}
                      to="/#"
                    >
                      
                      {this.props.t("My Loans")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isMyLoans,
                      })}
                    >
                      <Link to="monthlyloan" className="dropdown-item">
                        {this.props.t("Monthly Loan")}
                      </Link>
                      <Link to="cashloan" className="dropdown-item">
                        {this.props.t("Loan")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Store Credit")}
                      </Link>

                      <Link to="#" className="dropdown-item">
                        {this.props.t("Mortgage")}
                      </Link>

                      <Link to="#" className="dropdown-item">
                        {this.props.t("Reverse Mortgage")}
                      </Link>
                     
                    </div>
                  </li>



                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isMyTransactions: !this.state.isMyTransactions })
                      }}
                      to="/#"
                    >
                      
                      {this.props.t("My Transactions")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isMyTransactions,
                      })}
                    >
                      <Link to="/deposit" className="dropdown-item">
                        {this.props.t("Deposit Cash")}
                      </Link>


                      <Link to="/witdrawcash" className="dropdown-item">
                        {this.props.t("Withdraw Cash")}
                      </Link>

                      <Link to="/accounttoaccount" className="dropdown-item">
                        {this.props.t("Transfer Between My Accounts")}
                      </Link>

                      <Link to="/accounttoother" className="dropdown-item">
                        {this.props.t("My Account to another Account (Paystack)")}
                      </Link>

                      <Link to="/p2p" className="dropdown-item">
                        {this.props.t("Person To Person")}
                      </Link>


                      <Link to="/#" className="dropdown-item">
                        {this.props.t("Reverse Payment")}
                      </Link>

                      <Link to="/#" className="dropdown-item">
                        {this.props.t("Standing Order")}
                      </Link>
                     
                    </div>
                  </li>





                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isMyCaveo: !this.state.isMyCaveo })
                      }}
                      to="/#"
                    >
                      
                      {this.props.t("My CAVEO")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isMyCaveo,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Caveo Statement")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Buy Token")}
                      </Link>

                      <Link to="#" className="dropdown-item">
                        {this.props.t("Transfer Token")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Trade Token")}
                      </Link>
                     
                    </div>
                  </li>



                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isMyRecords: !this.state.isMyRecords })
                      }}
                      to="/#"
                    >
                      
                      {this.props.t("My Records")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isMyRecords,
                      })}
                    >
                      <Link to="/accountstatement" className="dropdown-item">
                        {this.props.t("My Statements")}
                      </Link>

                      <Link to={`/sharestatement`} className="dropdown-item">
                        {this.props.t("My Share Statement")}
                      </Link>

                      <Link to={`/mysynopsis/${obj.uid}`} className="dropdown-item">
                        {this.props.t("My Synopsis")}
                      </Link>

{obj.F_MembershipTypeMaster == 5 ? 
                      <Link to={`/edit-membershipentity/${obj.uid}`} className="dropdown-item">
                        {this.props.t("Update My Records")}
                      </Link> : <Link to={`/edit-membership/${obj.uid}`} className="dropdown-item">
                        {this.props.t("Update My Records")}
                      </Link> 
  }
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isMyApplications: !this.state.isMyApplications })
                      }}
                      to="/#"
                    >
                      {this.props.t("My Applications")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isMyApplications,
                      })}
                    >

                      {obj.F_MembershipTypeMaster  ==  5 ?
                     
                      
                      <Link to="/pagejoblist" className="dropdown-item">
                      {this.props.t("Job")}
                    </Link>
                    : 
                    <Link to="/newjoblist" className="dropdown-item">
                    {this.props.t("Job")}
                  </Link> }

                  {obj.F_MembershipTypeMaster  ==  5 ?
                     <Link to="/sellbusinesslist" className="dropdown-item">
                     {this.props.t("Sell Business")}
                   </Link>
                    : 
                      <Link to="/newopportunity" className="dropdown-item">
                        {this.props.t("New Opportunity")}
                      </Link>
                       }

              <Link to="/tickets" className="dropdown-item">
                                    {this.props.t("Raise Ticket")}
                                  </Link>
                     


                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              invoiceState: !this.state.invoiceState,
                            });
                          }}
                        >
                          {this.props.t("Notice of Transfer of Shares")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.invoiceState,
                          })}
                        >
                           <Link to="/transfershares/1" className={`dropdown-item ${window.location.pathname === '/transfershares/1' ? 'active' : 'inactive'}`} >
                        {this.props.t("Transfer Matured Shares")}
                        </Link>
                     
                          <Link to="/transfershares/2" className={`dropdown-item ${window.location.pathname === '/transfershares/2' ? 'active' : 'inactive'}`}>
                            {this.props.t("Transfer Pre-Mature Shares")}
                          </Link>
                        

                          
                        </div>
                      </div>

                      <Link to="/add-entity" className="dropdown-item">
                            {this.props.t("Entity")}
                          </Link>


                      <Link to="/#" className="dropdown-item">
                            {this.props.t("Business Person")}
                          </Link>
                      <Link to="/support" className="dropdown-item">
                            {this.props.t("Support")}
                          </Link>

                          <Link to="/maillist" className="dropdown-item">
                            {this.props.t("Support Mail List")}
                          </Link>

                         


                          <Link to="/#" className="dropdown-item">
                            {this.props.t("Mail")}
                          </Link>

                    
                    </div>
                  </li>




                  {/* <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ appState: !this.state.appState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me" />
                      {this.props.t("Self Service")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                    <Link to="#" className="dropdown-item">
                        {this.props.t("My Statement")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("My Membership Synopsis")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Inter-Account Transfer")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("My Transaction Account to Another")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Standing Order")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("Request for Reversal of Payment")}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {this.props.t("My Apllications")}
                      </Link>
                         <Link to="monthlyloan" className="dropdown-item">
                        {this.props.t("Monthly Loan")}
                      </Link>
                      <Link to="cashloan" className="dropdown-item">
                        {this.props.t("Loan")}
                      </Link>

                   
                    
                
                    </div>
                  </li> */}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
